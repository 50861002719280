import React from "react"
import { ContentBlockProps } from "../types"

import PrimaryCta, { themes } from "../../buttons/primary_cta"
import { Tldr } from "../../svgs"

// TODO: Consolidate styles with Story News block
// import styles from "../story_news_block/index.module.scss"
import styles from "./index.module.scss"

import { theme } from "../../_utils/constants"

// TODO: Consolidate component with Story News block
const ContentBlock = ({
  bodyCopy,
  ctaLabel,
  ctaLink,
  ctaPageUrl,
  className,
  isRootProgram,
  isRootStoryNews,
  title,
  titleClassName,
}: ContentBlockProps) => {
  const handleRoot = (slug) => {
    if (isRootProgram) {
      return `/what-we-do/${slug}`
    } else if (isRootStoryNews) {
      return `/stories-and-news/${slug}`
    } else {
      return `/${slug}`
    }
  }

  const handleLink = () => {
    // console.log(ctaPageUrl, ctaLink)

    if (typeof ctaPageUrl === "undefined" && ctaLink !== null) {
      return handleRoot(ctaLink)
    } else if (ctaPageUrl === "" && ctaLink !== null) {
      // console.log("ctaLink", ctaLink.slug)
      return handleRoot(ctaLink.slug)
    } else if (ctaLink === null || ctaPageUrl !== "") {
      return handleRoot(ctaPageUrl)
    } else {
      return "/"
    }
  }
  return (
    <div className={`${styles.block} ${className}`}>
      <div className={`${styles.block__head} ${styles.block__headCondense}`}>
        <h2 className={`typography__display--4 ${titleClassName} `}>{title}</h2>
        <div className={styles.block__tldr}>
          <Tldr color={theme.navy} />
        </div>
        <small
          className={`${styles.block__date} typography__display--exception`}
        ></small>
      </div>

      <div className={`${styles.block__body}`}>
        <p>{bodyCopy}</p>
        {/* <ReactMarkdown source={bodyCopy} /> */}
        <PrimaryCta label={ctaLabel} theme={themes.navy} to={handleLink()} />
      </div>
    </div>
  )
}
export default ContentBlock
