import React from "react"
import Head from "../components/global/head"

import { graphql } from "gatsby"
import HeaderBreadcrumbs from "../components/header_breadcrumbs"
import CtaBanner from "../components/modules/cta_banner"

import LogoGrid from "../components/logo_grid"
import HeroIntroBanner from "../components/modules/hero_intro_banner"

const OurProgramPartnerChildTemplate = ({ data, location }) => {
  const {
    ctaBannerModule,
    modules,
    seoTitle,
    seoKeywords,
    seoDescription,
    slug,
  } = data.datoCmsProgramPartnerChild

  const { heroTitle, heroText, backgroundImage } = modules[0]
  const ctaBanner = ctaBannerModule[0]

  const pageRoot = {
    name: "What We Do",
    path: "/what-we-do",
  }
  const breadcrumbs = [
    {
      name: "Partner Program",
      path: `${pageRoot.path}/partners/`,
    },
    {
      name: "Supporters",
      path: location.pathname,
    },
  ]
  const handleHasImage = () => {
    if (modules[0].backgroundImage) {
      return true
    } else {
      return false
    }
  }
  return (
    <>
      <Head
        title={seoTitle}
        url={`https://www.choosetap.com.au/what-we-do/${slug}`}
        description={seoDescription}
        keywords={seoKeywords}
        bodyClassname="pages__what-we-do"
      />
      <section>
        <HeroIntroBanner
          className="whatWeDoLanding"
          title={heroTitle}
          shortDescription={heroText}
          sectionName={`Partners`}
          themeColor="grey"
          image={backgroundImage}
          hasImage={handleHasImage()}
        >
          <HeaderBreadcrumbs
            isProgram={false}
            root={pageRoot}
            crumbs={breadcrumbs}
          />
        </HeroIntroBanner>
        <main>
          <LogoGrid isPartnerLogos={false} showLogosOnly={true} />
        </main>
        {ctaBannerModule.length > 0 && (
          <CtaBanner
            isFooterInstance={true}
            title={ctaBanner.title}
            ctaLabel={ctaBanner.ctaLabel}
            ctaLink={ctaBanner.ctaLink}
            ctaPageUrl={ctaBanner.ctaPageUrl}
            image={ctaBanner.backgroundImage}
          />
        )}
      </section>
    </>
  )
}

export default OurProgramPartnerChildTemplate

export const query = graphql`
  query($slug: String!) {
    datoCmsProgramPartnerChild(slug: { eq: $slug }) {
      seoTitle
      seoKeywords
      seoDescription
      slug
      modules {
        heroText
        heroTitle
        backgroundImage {
          url
          alt
        }
      }

      ctaBannerModule {
        __typename
        title
        backgroundImage {
          url
          alt
        }
        ctaLink {
          __typename
          ... on DatoCmsPost {
            slug
          }
          ... on DatoCmsProgram {
            slug
          }
        }
        ctaLabel
        ctaPageUrl
      }
    }
  }
`
